var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._t("icon"),
      _vm.visible
        ? _c("div", { staticClass: "modal" }, [
            _c("div", { staticClass: "main" }, [
              _c(
                "div",
                { staticClass: "headerBox" },
                [
                  _c("div", { staticClass: "title" }, [_vm._v("站内信")]),
                  _c("a-icon", {
                    staticClass: "close",
                    attrs: { type: "close" },
                    on: {
                      click: function ($event) {
                        _vm.visible = false
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "content" },
                [
                  _c(
                    "div",
                    { staticClass: "tabBox" },
                    _vm._l(_vm.tabList, function (key, index) {
                      return _c(
                        "div",
                        {
                          key: "tabItem" + index,
                          staticClass: "tabItem",
                          class: { active: _vm.currentKey === key.value },
                          on: {
                            click: function ($event) {
                              return _vm.tabsChange(key.value)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(key.label) + " ")]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    { staticClass: "opration" },
                    [
                      _c(
                        "a-button",
                        { attrs: { type: "link" }, on: { click: _vm.readAll } },
                        [_vm._v("全部已读")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "msgBox" },
                    [
                      _vm.loadComplate && _vm.newsList.length > 0
                        ? _vm._l(_vm.newsList, function (key, index) {
                            return _c(
                              "div",
                              {
                                key: "msgItem" + index,
                                staticClass: "msgItem",
                                on: {
                                  click: function ($event) {
                                    return _vm.alreadyReadMessage(
                                      key.platformMessageReceiveId,
                                      key
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "left" }, [
                                  _c("div", { staticClass: "msgTitle" }, [
                                    _vm._v(_vm._s(key.businessName)),
                                  ]),
                                  _c("div", { staticClass: "msgContent" }, [
                                    _vm._v(_vm._s(key.content)),
                                  ]),
                                ]),
                                _c("div", { staticClass: "right" }, [
                                  key.readState === "0"
                                    ? _c("div", { staticClass: "circle" })
                                    : _vm._e(),
                                  _vm._v(" " + _vm._s(key.createTime) + " "),
                                ]),
                              ]
                            )
                          })
                        : [_c("NoData")],
                    ],
                    2
                  ),
                  _vm.newsList.length > 0
                    ? _c("a-pagination", {
                        staticClass: "pageNation",
                        attrs: {
                          "show-quick-jumper": "",
                          size: "small",
                          "default-current": 1,
                          current: _vm.current,
                          total: _vm.total,
                        },
                        on: { change: _vm.onChange },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }